<template>
  <div>
   <v-container>
      <v-row>
        <v-col cols="3">
          <v-text-field
            v-model.number="editedItem.fiscalYear"
            id="collateralBankTransItemFYSelect"
            class="collateral-bank-trans-item-fy-select"
            label="Fiscal Year"
            type="number"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <ClarionDateControl
            v-model="editedItem.asOfDate"
            id="collateralBankTransItemAsOfDate"
            class="collateral-bank-trans-item-as-of-date"
            label="As Of Date"
            :isIsoDate="true"
            :isReadOnly="true"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="editedItem.bankIntials"
            id="collateralBankTransItemBankIntialsText"
            class="collateral-bank-trans-item-bank-intials-text"
            label="Bank Intials"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-text-field
            v-model="editedItem.bankName"
            id="collateralBankTransItemBankNameText"
            class="collateral-bank-trans-item-bank-name-text"
            label="Bank Name"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="editedItem.cashBalance"
            id="collateralBankTransItemCashBalanceText"
            class="collateral-bank-trans-item-cash-balance-text"
            label="Cash Balance"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
        <CollateralBankDetails
          v-model="editedItem.details"
          :baseCrudKey="baseCrudKey"
        />
      </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CollateralBankDetails from './CollateralBankDetails.vue';
import ClarionDateControl from '../../common/ClarionDateControl.vue';

export default {
  name: 'CollateralBankEditedItem',
  components: {
    CollateralBankDetails,
    ClarionDateControl,
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'collateralBank-dialog',
    },
  },
  created() {
  },
  computed: {
    ...mapGetters('base/crud', [
      'item',
      'items',
      'index',
      'isVisible',
    ]),
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
  },
  methods: {
    ...mapMutations('base/crud', [
      'setItem',
    ]),
  },
};
</script>
