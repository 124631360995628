<template>
  <div
    class="HeadersTotalsList">
    <CollateralBankCriteria
      :baseCrudKey="baseCrudKey"
      @loadCRUDList="handleLoadCRUDList"
    />
    <CRUDList
      :canAdd="true"
      :canEdit="true"
      :canSave="true"
      :headers="headers"
      :showDate="true"
      :showTotal="true"
      :baseCrudKey="baseCrudKey"
      :createNewItem="newItemDialog"
      toolbarTitle="Collateral Bank"
      @loadCRUDList="handleLoadCRUDList"
      @upsertItem="upsrtTransaction"
    >
      <template v-slot:[`editedItem`]="{}">
        <CollateralBankEditedItem
          :baseCrudKey="baseCrudKey"
        />
      </template>
    </CRUDList>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';
import CRUDList from '../../common/base/crud/CRUDList.vue';
import CollateralBankCriteria from './CollateralBankCriteria.vue';
import CollateralBankEditedItem from './CollateralBankEditedItem.vue';
import { amountToNumber } from '../../../util/shared/vue-global';
import { toIsoDate } from '../../../util/shared/tmc-global';

const getHeaders = () => {
  const headers = [
    { text: 'Bank', value: 'bankIntials' },
    { text: 'Bank Name', value: 'bankName' },
    { text: 'Cash Balance', value: 'cashBalance' },
    { text: 'Pledges Left', value: 'pledgesLeft' },
  ];
  return headers;
};

export default {
  data: () => ({
    baseCrudKey: 'collateralBank',
    headers: [],
  }),
  created() {
    this.headers = getHeaders();
    this.initCriteria();
  },
  components: {
    CRUDList,
    CollateralBankCriteria,
    CollateralBankEditedItem,
  },
  computed: {
    ...mapGetters(['todaysDate']),
    ...mapGetters('base/crud', [
      'criteria',
      'index',
      'item',
      'items',
    ]),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
  },
  methods: {
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),
    ...mapActions('glDaily/glCollateralBankTransaction', [
      'loadGLCollateralBankTrans',
      'upsertGLCollateralBankTrans',
    ]),
    handleLoadCRUDList() {
      const { baseCrudKey } = this;
      this.loadGLCollateralBankTrans({ criteria: this.criteriaState, baseCrudKey });
    },
    initCriteria() {
      this.criteriaState = {};
    },
    async upsrtTransaction(item) {
      const { baseCrudKey } = this;
      const updatedItem = { ...item };
      updatedItem.cashBalance = amountToNumber(updatedItem.cashBalance);
      updatedItem.pledgesLeft = amountToNumber(updatedItem.pledgesLeft);
      if ((updatedItem.details || []).length > 0) {
        updatedItem.details = updatedItem.details.map((element) => {
          const detailItem = { ...element };
          detailItem.amount = amountToNumber(detailItem.amount);
          detailItem.fdic = amountToNumber(detailItem.fdic);
          if (detailItem.asOfDate) {
            detailItem.asOfDate = toIsoDate(detailItem.asOfDate);
          } else {
            detailItem.asOfDate = null;
          }
          return {
            ...detailItem,
          };
        });
      }
      const response = await this.upsertGLCollateralBankTrans({ item: updatedItem, baseCrudKey });
      return response;
    },
    newItemDialog() {
      let newItem = {};
      newItem = {
        asOfDate: this.todaysDate,
        // fiscalYear: this.criteriaState.fiscalYear || toFiscalYear(new Date(this.todaysDate)),
        bankIntials: '',
        bankName: '',
        cashBalance: 0.0,
        pledgesLeft: '',
        details: [{
          asOfDate: null,
          description: '',
          amount: 0.0,
          fdic: 0.0,
        }],
      };
      return newItem;
    },
  },
};
</script>

<style>

</style>
