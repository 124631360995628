<template>
  <v-data-table
    dense
    :headers="headers"
    :items-per-page="-1"
    :items="editedItem"
    hide-default-footer
    class="elevation-1"
  >
    <template v-slot:top>
      <div class="d-flex justify-end">
        <v-btn
          class="mx-2" color="primary"
          fab icon x-small
          @click="addOne"
        >
          <v-icon>
            {{icons.mdiPlus}}
          </v-icon>
        </v-btn>
      </div>
    </template>

    <template v-slot:[`item.asOfDate`]="{ item }">
      <ClarionDateControl
        v-model="item.asOfDate"
        label="As Of Date"
        :isIsoDate="true"
        dense
        single-line
        @blurred="somethingChanged"
      />
    </template>

    <template v-slot:[`item.description`]="{ item }">
      <v-text-field
        v-model="item.description"
        label="Description"
        dense
        single-line
        @blur="somethingChanged"
        @keydown.enter="somethingChanged"
      ></v-text-field>
    </template>

    <template v-slot:[`item.amount`]="{ item }">
      <v-text-field
        v-model="item.amount"
        dense
        single-line
        label="Amount"
        @blur="somethingChanged"
        @keydown.enter="somethingChanged"
      ></v-text-field>
    </template>

    <template v-slot:[`item.fdic`]="{ item }">
      <v-text-field
        v-model="item.fdic"
        label="FDIC"
        dense
        single-line
        @blur="somethingChanged"
        @keydown.enter="somethingChanged"
      ></v-text-field>
    </template>

    <template v-slot:[`item.delActions`]="{ item }">
      <v-icon
        small class="mr-2"
        tabindex="-1"
        @click="removeOne(item)"
      >
        {{icons.mdiDelete}}
      </v-icon>
    </template>

    <template v-slot:[`item.editActions`]="{ }">
      <v-icon
        small class="mr-2"
        tabindex="-1"
      >
        {{icons.mdiPencil}}
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import {
  mdiPlus,
  mdiDelete,
  mdiPencil,
} from '@mdi/js';
import {
  amountFormat,
  amountToNumber,
} from '../../../util/shared/vue-global';
import ClarionDateControl from '../../common/ClarionDateControl.vue';

const defaultItem = {
  asOfDate: '',
  description: '',
  amount: 0.0,
  fdic: 0.0,
};

export default {
  name: 'CollateralBankDetails',
  components: {
    ClarionDateControl,
  },
  created() {
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'collateralBankDetails-dialog',
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    // isEditing: false,
    icons: {
      mdiPlus,
      mdiDelete,
      mdiPencil,
    },
    defaultHeaders: [
      { text: 'As Of Date', value: 'asOfDate' },
      { text: 'Description', value: 'description' },
      { text: 'Amount', value: 'amount' },
      { text: 'FDIC', value: 'fdic' },
      { text: 'Delete', value: 'delActions', sortable: false },
      { text: 'Edit', value: 'editActions', sortable: false },
    ],
  }),
  computed: {
    headers() {
      return this.defaultHeaders;
    },
    editedItem: {
      get() {
        const newVal = JSON.parse(JSON.stringify(this.value));
        const updatedValue = newVal.map(this.loadInitialValues);
        return updatedValue;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    addOne() {
      this.$emit('input', [
        ...this.editedItem,
        {
          ...defaultItem,
        },
      ]);
      return true;
    },
    removeOne(item) {
      this.$emit('input', [
        ...this.editedItem.filter((candidate) => item !== candidate),
      ]);
    },
    loadInitialValues(item) {
      return {
        ...item,
        amount: amountFormat(amountToNumber(item.amount)),
        fdic: amountFormat(amountToNumber(item.fdic)),
      };
    },
    somethingChanged() {
      this.$emit('input', this.editedItem);
    },
    // incomplete functionality
    // editOne(index) {
    //   console.info((index + 1) === (this.editedItem || []).length, '??');
    //   console.info((index), '??');
    //   this.isEditing = !this.isEditing;
    //   // console.info(this.isEditing);
    // },
  },
};
</script>

<style lang="sass">
  .eft-rec-amount input
    text-align: right
</style>
