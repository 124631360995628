<template>
 <div>
    <CRUDCriteria
      :showReset="true"
      :showSearch="false"
    >
      <template v-slot:criteriaRows>
        <v-row>
          <v-col cols="3">
            <v-select
              v-model="criteriaState.fiscalYear"
              class="gl-collateral-bank-criteria-FY-select"
              id="glCollateralBankCriteriaFYSelect"
              label="By FY"
              @input="searchByCriteria"
            >
            </v-select>
          </v-col>
        </v-row>
      </template>
    </CRUDCriteria>
  </div>
</template>
<script>
import {
  mapGetters,
} from 'vuex';
import CRUDCriteria from '../../common/base/crud/CRUDCriteria.vue';

export default {
  name: 'CollateralBankCriteria',
  components: {
    CRUDCriteria,
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'transactionCriteria',
    },
  },
  data: () => ({
  }),
  created() {
  },
  computed: {
    ...mapGetters('base/crud', ['criteria', 'item', 'items']),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
  },
  methods: {
    searchByCriteria() {
      this.handleLoadCRUDList();
    },
    handleLoadCRUDList() {
      this.$emit('loadCRUDList');
    },
  },
};
</script>
