var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items-per-page":-1,"items":_vm.editedItem,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","fab":"","icon":"","x-small":""},on:{"click":_vm.addOne}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)],1)]},proxy:true},{key:"item.asOfDate",fn:function(ref){
var item = ref.item;
return [_c('ClarionDateControl',{attrs:{"label":"As Of Date","isIsoDate":true,"dense":"","single-line":""},on:{"blurred":_vm.somethingChanged},model:{value:(item.asOfDate),callback:function ($$v) {_vm.$set(item, "asOfDate", $$v)},expression:"item.asOfDate"}})]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"Description","dense":"","single-line":""},on:{"blur":_vm.somethingChanged,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.somethingChanged($event)}},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"dense":"","single-line":"","label":"Amount"},on:{"blur":_vm.somethingChanged,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.somethingChanged($event)}},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}})]}},{key:"item.fdic",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"FDIC","dense":"","single-line":""},on:{"blur":_vm.somethingChanged,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.somethingChanged($event)}},model:{value:(item.fdic),callback:function ($$v) {_vm.$set(item, "fdic", $$v)},expression:"item.fdic"}})]}},{key:"item.delActions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","tabindex":"-1"},on:{"click":function($event){return _vm.removeOne(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"item.editActions",fn:function(ref){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","tabindex":"-1"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }